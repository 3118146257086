import React from 'react';
import { connect } from 'react-redux';

import { signIn, signOut } from '../../actions';

class GoogleAuth extends React.Component {
	
	componentDidMount() {
		window.gapi.load('client:auth2', () => {
			window.gapi.client
			.init({
				clientId: '500558601827-nsik8ijiqls1n017u4e0l6scincve71m.apps.googleusercontent.com',
				scope: 'email'
			})
			.then(() => {
				this.auth = window.gapi.auth2.getAuthInstance();
				this.onAuthChange(this.auth.isSignedIn.get());
				this.auth.isSignedIn.listen(this.onAuthChange);
			});
		});
	}

	onAuthChange = isSignedIn => {
		if (isSignedIn) {
			this.props.signIn(this.auth.currentUser.get().getId());
		} else {
			this.props.signOut();
		}
	}
	
	onSignInBtnClick = () => {
		this.auth.signIn();
	}
	
	onSignOutBtnClick = () => {
		this.auth.signOut();
	}
	
	renderProfile() {
		console.log(this.auth.currentUser.get());
		let userData = this.auth.currentUser.get();
		if (userData) {
			return (
				<div className="Profile">
					<img className="Profile__img" src={userData.Lu.rN} alt={userData.Ad} />
					<div className="Profile__name">{userData.Lu.iY}</div>
					<div className="Profile__btn-container">
						<button className="AuthBlock__btn" onClick={this.onSignOutBtnClick}>Вийти</button>
					</div>
				</div>
			);
		} else {
			return <button  className="AuthBlock__btn" onClick={this.onSignInBtnClick}>Увійти</button>;
		}
		
		
	}

	renderSignIn() {
		return <button  className="AuthBlock__btn" onClick={this.onSignInBtnClick}>Увійти</button>;
	}

	renderAuthBlock() {
		if(this.props.isSignedIn === null) {
			return null;
		} else if(this.props.isSignedIn) {
			return this.renderProfile();
		} else {
			return this.renderSignIn();
		}
	}
	
	render() {
		return <div className="AuthBlock">{this.renderAuthBlock()}</div>;
	}
}

const mapStateToProps = state => {
	return { isSignedIn: state.auth.isSignedIn };
}

export default connect(mapStateToProps, { signIn, signOut })(GoogleAuth);