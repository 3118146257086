import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { fetchCatsList, setActiveClass } from '../../actions';

class SideMenu extends React.Component {
	
	componentDidMount() {
		this.toggleBtnClass = this.toggleBtnClass.bind(this)
		this.props.fetchCatsList();
		if(this.props.rssId) {
			this.props.setActiveClass(true);
		}
	}
	
	componentDidUpdate(prevProps) {
		if(this.props.rssId && this.props.rssId !== prevProps.rssId) {
			this.props.setActiveClass(true);
		} else if (this.props.rssId !== prevProps.rssId) {
			this.props.setActiveClass(false);
		}
	}
	
	renderCatsList() {
		let catsArray = Object.values(this.props.cats);
		let catsList = "";
		if (catsArray.length > 0) {
			catsList = catsArray.map(item => {
				return (
					<li className={`SideMenu__item SideMenu__item--anim ${item.id === this.props.catId ? 'SideMenu__item--active' : ''}`} key={item.id}>
						<Link className="SideMenu__link" to={`/news/by-topic/${item.id}`}>
							<FontAwesome className="SideMenu__icon" name={item.fa_icon} />
							<span className="SideMenu__item-title">{item.title}</span>
						</Link>
					</li>
				);
			});
		}
		return catsList;
	}
	
	renderRssList() {
		let rssArray = Object.values(this.props.rss);
		let rssList = "";
		if (rssArray.length > 0) {
			rssList = rssArray.map(item => {
				return (
					<li className={`SideMenu__item SideMenu__item--anim ${item.id === this.props.rssId ? 'SideMenu__item--active' : ''}`} key={item.id}>
						<Link className="SideMenu__link" to={`/news/by-source/${item.id}`}>
							<FontAwesome className="SideMenu__icon" name='chevron-right' />
							<span className="SideMenu__item-title">{item.title}</span>
						</Link>
					</li>
				);
			});
		}
		return rssList;
	}
	
	toggleBtnClass() {
        this.props.setActiveClass(!this.props.activeClass);
	}
	
	render() {
		return (
			<ul className="SideMenu">
				<li className={`SideMenu__item SideMenu__item--anim ${this.props.location.pathname === '/' ? 'SideMenu__item--active' : ''}`}>
					<Link className="SideMenu__link" to='/'>
						<FontAwesome className="SideMenu__icon" name='globe' />
						<span className="SideMenu__item-title">Всі новини</span>
					</Link>
				</li>
				{this.renderCatsList()}
				<li className="SideMenu__item">
					<button className="SideMenu__link SideMenu__link--button" onClick={this.toggleBtnClass}>
						<FontAwesome className="SideMenu__icon" name='rss' />
						<span className="SideMenu__item-title">Джерела</span>
					</button>
					<ul className={`SideMenu__sub-list ${this.props.activeClass ? 'SideMenu__sub-list--active' : ''}`}>
						{this.renderRssList()}
					</ul>
				</li>
			</ul>
		);
	}
}

const mapStateToProps = state => {
	return { 
		cats: state.menu.cats, 
		rss: state.menu.rss, 
		catId: state.ids.catId, 
		rssId: state.ids.rssId, 
		activeClass: state.activeClass.active
	}
};

export default connect(mapStateToProps, { fetchCatsList, setActiveClass })(withRouter(SideMenu));