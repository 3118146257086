import React from 'react';

class Footer extends React.Component {
	
	render() {
		
		return (
			<footer className="Footer">
				<div className="Footer__text mb-s">Сайт ВсіЗМІ не є власником опублікованих на ньому новин. Це агрегатор, який у автоматичному режимі збираї, сортує на аналізує публікації з інших сайтів. Якщо Ви власник одного з новинних джерел і проти, щоб Ваші публікації з’являлися на цьому сайті, або, навпаки, хочете запропонувати свій сайт для агрегації ВсіЗМІ, будь ласка, зверніться у техпідтримку: <a href="mailto:info@vsizmi.co.ua">info@vsizmi.co.ua</a></div>
				<div className="Footer__copyright">
					&copy;ВсіЗМІ, 2007 – 2019. Розробка і дизайн — Олександр Антонюк
				</div>
			</footer>
		);
  	}
	
}

export default Footer;