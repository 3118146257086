import React from 'react';
import { Link } from 'react-router-dom';

import SearchForm from '../../components/SearchForm';
import GoogleAuth from '../../components/GoogleAuth';

import { ReactComponent as LogoSVG } from '../../assets/images/logo2.svg';

const Logo = () => {
	return (
		<div className="Header__logo">
			<Link to="/">
				<LogoSVG className="Header__logo-svg" />
			</Link>
		</div>
	);
}

const Header = () => {
	return (
		<header className="Header">
			<Logo />
			<SearchForm />
			<GoogleAuth />
		</header>
	);
};

export default Header;