import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { setSiteTitle } from '../actions';

//containers
import Header from './Header';
import Main from './Main';
import ShowNews from './ShowNews';
import SideBar from './SideBar';
import Footer from './Footer';
import NotFound from '../components/NotFound';

//components
import ScrollToTop from '../components/ScrollToTop';

//scss
import './App.scss';

class App extends React.Component {
	
	componentDidMount() {
		this.props.setSiteTitle('ВсіЗМІ');
	}

	render() {
		return (
			<BrowserRouter>
				<ScrollToTop />
				<div className="App">
					<Header />
					<div className="Content">
						<SideBar />
						<Switch>
							<Route path="/" exact component={Main} />
							<Route path="/news/by-topic/:cat" exact component={Main} />
							<Route path="/news/by-source/:rss" exact component={Main} />
							<Route path="/news/search/:search" exact component={Main} />
							<Route path="/news/:id" exact component={ShowNews} />
							<Route component={NotFound} />
						</Switch>
					</div>
					<Footer />
				</div>
			</BrowserRouter>
		);
	}
	
}

export default connect(null, { setSiteTitle })(App);