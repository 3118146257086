import React from 'react';
import FontAwesome from 'react-fontawesome';

import SideMenu from '../../components/SideMenu';

const SideBar = () => {
	
	const onArrowBtnClick = () => {
		let sideBar = document.querySelector('.SideBar');
		let sideBarIcon = document.querySelector('.SideBar-icon');
 		sideBar.classList.toggle('SideBar--active');
		//sideBarIcon.classList.toggle('SideBar-icon--active');
		sideBarIcon.classList.toggle('fa-arrow-alt-circle-right');
		sideBarIcon.classList.toggle('fa-arrow-alt-circle-left');
	}
	
	return (
		<>
			<FontAwesome onClick={onArrowBtnClick} className="SideBar-icon" name="arrow-alt-circle-right" />
			<nav className="SideBar">
				<SideMenu />
			</nav>
		</>
	);
};

export default SideBar;