import React from 'react';
import NewsItem from '../NewsItem';
import { connect } from 'react-redux';
import TrackVisibility from 'react-on-screen';
import { fetchNewsline, setUnreadStatus } from '../../actions';

class NewsLine extends React.Component {
	
	constructor(props) {
        super(props);
		this.onBtnVisabilityChange = this.onBtnVisabilityChange.bind(this);
		this.onClickLoadRead = this.onClickLoadRead.bind(this);
    }
	
	componentDidMount() {
		this.props.fetchNewsline(0, this.props.limit, this.props.cat, this.props.rss, true, this.props.userId, 1, this.props.skip, this.props.search);
	}
	
	componentDidUpdate(prevProps) {
		if (this.props.cat !== prevProps.cat || this.props.rss !== prevProps.rss || this.props.userId !== prevProps.userId || this.props.read !== prevProps.read || this.props.search !== prevProps.search) {
			this.props.fetchNewsline(0, this.props.limit, this.props.cat, this.props.rss, true, this.props.userId, this.props.read, this.props.skip, this.props.search);
		}
	}
	
	onBtnVisabilityChange(isVisible) {
		if(isVisible) {
			const start = this.props.newsline.length;
			this.props.fetchNewsline(start, this.props.limit, this.props.cat, this.props.rss, false, this.props.userId, this.props.read, this.props.skip, this.props.search);
		}
	}
	
	onClickLoadRead() {
		this.props.setUnreadStatus(0);
		this.props.fetchNewsline(0, this.props.limit, this.props.cat, this.props.rss, true, this.props.userId, 0, this.props.skip, this.props.search);
	}
	
	onLoadHideSidebar() {
		let sideBar = document.querySelector('.SideBar');
		let sideBarIcon = document.querySelector('.SideBar-icon');
 		sideBar.classList.remove('SideBar--active');
		sideBarIcon.classList.toggle('fa-arrow-alt-circle-right');
		sideBarIcon.classList.toggle('fa-arrow-alt-circle-left');
	}
	
	renderList() {
		let newsArray = this.props.newsline;
		let newsList = '';
		if (newsArray.length > 0) {
			newsList = newsArray.map(item => {
				let itemClassName = '';
				if ((item.userID && item.userID === this.props.userId) || item.archive) {
					itemClassName = 'NewsItem--read';
				}
				return (
					<NewsItem key={item.id} data={item} userId={this.props.userId} itemClassName={itemClassName} />
				);
			});
		}
		
		if (this.props.hasMore) {
			return (
				<>
					{newsList}
					<div className="NewsLine__bt-message">Завантажується...</div>
					<TrackVisibility>
						{({ isVisible }) => this.onBtnVisabilityChange(isVisible) }
					</TrackVisibility>
				</>
			);
		} else if (this.props.hasMore === false && this.props.userId) {
			return (
				<>
					{newsList}
					<div className="NewsLine__bt-message">
						<button className={`NewsLine__bt-btn ${this.props.read === 0 ? 'NewsLine__bt-btn--hidden' : ''}`} onClick={this.onClickLoadRead}>Показати вже прочитані</button>
					</div>
				</>
			);
		} else if (this.props.hasMore === false) {
			return (
				<>
					{newsList}
				</>
			);
		}
	}
	
	render() {
		return (
			<div className="NewsLine">
				<div className="NewsLine__container">
					{this.renderList()}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		newsline: state.newsline.items, 
		hasMore: state.newsline.hasMore, 
		userId: state.auth.userId,
		read: state.read.show,
	}
};

export default connect(mapStateToProps, { fetchNewsline, setUnreadStatus })(NewsLine);