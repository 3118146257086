import React from 'react';
import Article from '../../components/Article';
import NewsLine from '../../components/NewsLine';
import { connect } from 'react-redux';
import { fetchNewsitem, setCatId, setRssId, setNewsRead } from '../../actions';

class ShowNews extends React.Component {
	
	componentDidMount() {
		this.props.fetchNewsitem(this.props.match.params.id);
	}
	
	componentDidUpdate(prevProps) {
		if (this.props.item !== prevProps.item) {
			document.title = `${this.props.siteTitle}: ${this.props.item.title}`;
			this.props.setCatId(this.props.item.cat_id);
			this.props.setRssId(this.props.item.rss_id);
			if (this.props.userID) {
				this.props.setNewsRead(this.props.userID, this.props.item.id);
			}
		}
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.props.fetchNewsitem(this.props.match.params.id);
		}
		if(this.props.error !== prevProps.error) {
		   if(this.props.error) {
				this.props.history.push('/404');
			}
		}
	}
	
	renderNewsline() {
		if (this.props.item.cat_id && this.props.item.rss_id) {
			return (
				<NewsLine
					limit = "5"
					cat = {this.props.item.cat_id}
					rss = {this.props.item.rss_id}
					skip = {this.props.item.id}
					search = ""
					/>
			);
		}
	}
	
	render() {
		return (
			<div className="ShowNews">
				<Article data={this.props.item} />
				<h2 className="heading-2 mb-s">Також у рубриці</h2>
				{ this.renderNewsline() }
			</div>
		);
	}
}

const mapStateToProps = state => {
	return { 
		item: state.newsitem.item,
		error: state.newsitem.error,
		catId: state.ids.catId, 
		rssId: state.ids.rssId, 
		setRead: state.setRead.status, 
		userID: state.auth.userId,
		siteTitle: state.siteTitle.title };
};

export default connect(mapStateToProps, { fetchNewsitem, setCatId, setRssId, setNewsRead })(ShowNews);