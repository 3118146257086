import React from 'react';
import { Link } from 'react-router-dom';

const Article = props => {
	
	const renderImg = () => {
		let imageHtml = "";
		if (props.data.img) {
			imageHtml = <img className="Article__img mb-s" src={ `https://novyny.pp.ua${props.data.img}` } alt={props.data.title} />;
		}
		return imageHtml;
	}
	
	return (
		<article className="Article mb-m">
			<div className="Article__container">
				<h1 className="heading-1 mb-s">{props.data.title}</h1>
				<div className="Article__atrbs mb-xs">
					<Link to={ `/sources/${props.data.rss_id}` } className="Article__source">{props.data.rss_title}</Link>
					<Link to={ `/categories/${props.data.cat_id}` } className="Article__category">{props.data.cat}</Link>
					<span className="Article__time">{props.data.pub_date}</span>
				</div>
				{renderImg()}
				<div className="Article__body mb-s" dangerouslySetInnerHTML={{__html: props.data.text}} />
				<p className="Article__url-original">Оригінал публікації: 
					<a target="_blank" rel="noopener noreferrer" href={props.data.url}>{props.data.url}</a>
				</p>
			</div>
		</article>
	);
};

export default Article;