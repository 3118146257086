import news from '../apis/news';

export const SIGN_IN = 'SIGN_IN';
export const signIn = userId => {
	return {
		type: SIGN_IN,
		payload: userId
	};
};

export const SIGN_OUT = 'SIGN_OUT';
export const signOut = () => {
	return {
		type: SIGN_OUT
	};
};
 
export const FETCH_NEWSLINE_REQUEST = 'FETCH_NEWSLINE_REQUEST';
export const FETCH_NEWSLINE_FAILURE = 'FETCH_NEWSLINE_FAILURE';
export const FETCH_NEWSLINE_SUCCESS = 'FETCH_NEWSLINE_SUCCESS';
const fetchNewslineRequest = init => {
	return {
        type: FETCH_NEWSLINE_REQUEST
    }
};

const fetchNewslineSuccess = (newsline, init) => {	
	return {
        type: FETCH_NEWSLINE_SUCCESS,
        payload: newsline,
		status: init
    }
};

const fetchNewslineFailure = error => {
    return {
        type: FETCH_NEWSLINE_FAILURE,
        error: error
    }
};

export const fetchNewsline = (start, limit, cat, rss, init, userId, unread, skip = null, search = '') => {
    return (dispatch, getState) => {
        let currentState = getState();
		if(userId && unread) {
			start = 0;
		}
		if (!currentState.newsline.pending) {
			dispatch(fetchNewslineRequest());
			fetch(`https://novyny.pp.ua/api/news/all?start=${start}&limit=${limit}&cat=${cat}&rss=${rss}&userid=${userId}&unread=${unread}&skip=${skip}&search=${search}`)
			.then(responce => responce.json())
			.then(responce => {
				if(responce.error) {
					throw(responce.error);
				}
				dispatch(fetchNewslineSuccess(responce, init));
				return responce;
			})
			.catch(error => {
				dispatch(fetchNewslineFailure(error));
			})
		}
    }
};


export const FETCH_NEWSITEM_REQUEST = 'FETCH_NEWSITEM_REQUEST';
export const FETCH_NEWSITEM_FAILURE = 'FETCH_NEWSITEM_FAILURE';
export const FETCH_NEWSITEM_SUCCESS = 'FETCH_NEWSITEM_SUCCESS';
const fetchNewsitemRequest = init => {
	return {
        type: FETCH_NEWSITEM_REQUEST
    }
};

const fetchNewsitemSuccess = (newsitem) => {
	return {
        type: FETCH_NEWSITEM_SUCCESS,
        payload: newsitem
    }
};

const fetchNewsitemFailure = error => {
    return {
        type: FETCH_NEWSITEM_FAILURE,
        error: error
    }
};

export const fetchNewsitem = id => {
    return (dispatch, getState) => {
        let currentState = getState();
		if (!currentState.newsitem.pending) {
			dispatch(fetchNewsitemRequest());
			fetch(`https://novyny.pp.ua/api/news/show?id=${id}`)
			.then(responce => responce.json())
			.then(responce => {
				if(responce.error) {
					throw(responce.error);
				}
				dispatch(fetchNewsitemSuccess(responce));
				return responce;
			})
			.catch(error => {
				dispatch(fetchNewsitemFailure(error));
			})
		}
    }
};


export const SET_VISIBLE = 'SET_VISIBLE';
export const setNewsVisible = news_id => {
	return {
        type: SET_VISIBLE,
        payload: news_id
    }
};

export const SET_READ = 'SET_READ';
export const setNewsRead = (user_id, news_id) => async dispatch => {
	const responce = await news.get(`/news/read?userid=${user_id}&newsid=${news_id}`);
	dispatch({ type: SET_READ, payload: responce.data });
};


export const FETCH_CATS_LIST = 'FETCH_CATS_LIST';
export const fetchCatsList = () => async dispatch => {
	const responce = await news.get('/cats/list');
	
	dispatch({ type: FETCH_CATS_LIST, payload: responce.data });
};

export const CAT_ID = 'CAT_ID';
export const setCatId = catId => {
	return {
		type: CAT_ID,
		payload: catId
	};
};

export const RSS_ID = 'RSS_ID';
export const setRssId = rssId => {
	return {
		type: RSS_ID,
		payload: rssId
	};
};

export const CLASS_ACTIVE = 'CLASS_ACTIVE';
export const setActiveClass = status => {
	return {
		type: CLASS_ACTIVE,
		payload: status
	};
};

export const UNREAD_STATUS = 'UNREAD_STATUS';
export const setUnreadStatus = status => {
	return {
		type: UNREAD_STATUS,
		payload: status
	};
};

export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const setSearchQuery = query => {
	return {
		type: SET_SEARCH_QUERY,
		payload: query
	};
};

export const SET_SITE_TITLE = 'SET_SITE_TITLE';
export const setSiteTitle = siteTitle => {
	return {
		type: SET_SITE_TITLE,
		payload: siteTitle
	};
};