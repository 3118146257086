import React from 'react';

const NotFound = props => {
	
	return (
		<div className="NotFound">
			<h1 className="NotFound__heading-1">404</h1>
			<p className="NotFound__text">Такої сторінки не існує</p>
		</div>
	);
};

export default NotFound;