import { combineReducers } from 'redux';

import { 
		SIGN_IN, 
		SIGN_OUT,
		FETCH_NEWSLINE_REQUEST,
		FETCH_NEWSLINE_FAILURE,
		FETCH_NEWSLINE_SUCCESS,
		FETCH_NEWSITEM_REQUEST,
		FETCH_NEWSITEM_FAILURE,
		FETCH_NEWSITEM_SUCCESS,
		SET_VISIBLE,
		FETCH_CATS_LIST,
		CAT_ID,
		RSS_ID,
		CLASS_ACTIVE,
		UNREAD_STATUS,
		SET_READ,
		SET_SEARCH_QUERY,
		SET_SITE_TITLE
} from '../actions';


const authReducer = (state = {
								isSignedIn: null,
								userId: null
							}, action) => {
	switch(action.type) {
		case SIGN_IN:
			return { ...state, isSignedIn: true, userId: action.payload };
		case SIGN_OUT:
			return { ...state, isSignedIn: false, userId: null };
		default:
			return state;
	}
};


const newslineReducer = (state = {
								pending: false,
								items: [],
								error: null,
								hasMore: null
							}, action) => {
    switch(action.type) {
        case FETCH_NEWSLINE_REQUEST: 
            return {
                ...state,
                pending: true
            }
        case FETCH_NEWSLINE_SUCCESS:
			
			let prevNews;
			if(action.status) {
				prevNews = Object.values(action.payload.news);
			} else {
				let loadedNews = Object.values(action.payload.news);
				prevNews = [...state.items];
				loadedNews.map(item => {
					var found = false;
					for(var i = 0; i < prevNews.length; i++) {
						if (prevNews[i].id === item.id) {
							found = true;
							break;
						}
					}
					if (!found) {
						prevNews.push(item);
					}
					return found;
					//if (prevNews.some(e => e.id !== item.id)) {
					//if (prevNews.length === 0 || prevNews.filter(e => {if(e.id !== item.id) {return true} else console.log(e.id)}).length > 0) {
						//prevNews.push(item);
					//}
					//console.log(prevNews);
				});
			}
			
			return {
                ...state,
                pending: false,
                //items: action.status ? loadedNews : [...state.items, ...loadedNews],
				items: prevNews,
				hasMore: action.payload.hasMore
            }
        case FETCH_NEWSLINE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default: 
            return state;
    }
};



const newsitemReducer = (state = {
								pending: false,
								item: {},
								error: null
							}, action) => {
	switch (action.type) {
		case FETCH_NEWSITEM_REQUEST: 
            return {
                ...state,
                pending: true
            }
		case FETCH_NEWSITEM_SUCCESS:
			return {
                ...state,
                pending: false,
				item: action.payload
            }
		case FETCH_NEWSITEM_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default: 
            return state;
	}
};


const newsStatusReducer = (state = { id: null }, action) => {
	switch(action.type) {
		case SET_VISIBLE:
			return { ...state, id: action.payload };
		default:
			return state;
	}
};



const setRead = (state = {}, action) => {
	switch(action.type) {
		case SET_READ:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};


const menu = (state = {
								cats: {},
								rss: {}
							}, action) => {
	switch (action.type) {
		case FETCH_CATS_LIST:
			return { ...state, cats: action.payload.cats, rss: action.payload.rss };
		default:
			return state;
	}
};


const ids = (state = {
								catId: null,
								rssId: null
							}, action) => {
	switch(action.type) {
		case CAT_ID:
			return { ...state, catId: action.payload, rssId: state.rssId };
		case RSS_ID:
			return { ...state, catId: state.catId, rssId: action.payload };
		default:
			return state;
	}
};


const activeClass = (state = { active: false }, action) => {
	switch(action.type) {
		case CLASS_ACTIVE:
			return { ...state, active: action.payload };
		default:
			return state;
	}
};


const read = (state = { show: 1 }, action) => {
	switch(action.type) {
		case UNREAD_STATUS:
			return { ...state, show: action.payload };
		default:
			return state;
	}
};


const searchQuery = (state = {value: ''}, action) => {
	switch(action.type) {
		case SET_SEARCH_QUERY:
			return { ...state, value:action.payload };
		default:
			return state;
	}
};

const siteTitle = (state = { title: 'vsizmi' }, action) => {
	switch(action.type) {
		case SET_SITE_TITLE:
			return { ...state, title: action.payload };
		default:
			return state;
	}
};

export default combineReducers({
	auth: authReducer,
	newsline: newslineReducer,
	newsitem: newsitemReducer,
	newsStatus: newsStatusReducer,
	menu,
	ids,
	activeClass,
	read,
	setRead,
	searchQuery,
	siteTitle
});