import React from 'react';
import { connect } from 'react-redux';
import NewsLine from '../../components/NewsLine';
import { setCatId, setRssId } from '../../actions';

class Main extends React.Component {
	
	componentDidMount() {
		this.props.setCatId(this.defineCat(this.props.match.params.cat));
		this.props.setRssId(this.defineRss(this.props.match.params.rss));
	}
	
	componentDidUpdate(prevProps) {
  		if (this.props.match.params.cat !== prevProps.match.params.cat) {
			this.props.setCatId(this.defineCat(this.props.match.params.cat));
		}
		if (this.props.match.params.rss !== prevProps.match.params.rss) {
			this.props.setRssId(this.defineRss(this.props.match.params.rss));
		}
		this.defineSiteTitle();
	}
	
	defineSiteTitle() {
		let notFound = false;
		if(this.props.match.params.cat && this.props.cats.length > 0) {
			let find_cat = this.props.cats.filter(obj => {
			  return obj.id === this.props.match.params.cat;
			});
			if(find_cat.length > 0) {
				document.title = `${this.props.siteTitle}: ${find_cat[0].title}`;
			} else {
				notFound = true;
			}
		} else if(this.props.match.params.rss && this.props.rss.length > 0) {
			let find_rss = this.props.rss.filter(obj => {
			  return obj.id === this.props.match.params.rss;
			});
			if(find_rss.length > 0) {
				document.title = `${this.props.siteTitle}: Новини від ${find_rss[0].title}`;
			} else {
				notFound = true;
			}
		} else {
			document.title = this.props.siteTitle;
		}
		if(notFound) {
			this.props.history.push('/404');
		}
	}
	
	defineRss(rss) {
		if(rss) {
			return rss;
		}
		return null;
	}
	
	defineCat(cat) {
		if(cat) {
			return cat;
		}
		return null;
	}
	
	render() {
		let cat = null;
		let rss = null;
		let search = '';
		if (this.props.match.params.cat) {
			cat = this.props.match.params.cat;
		}
		if (this.props.match.params.rss) {
			rss = this.props.match.params.rss;
		}
		if (this.props.match.params.search) {
			search = this.props.match.params.search;
		}
		else {
			search = '';
		}
		return (
			<div className="Main">
				<NewsLine
					limit = "5"
					cat = {cat}
					rss = {rss}
					skip = {null}
					search = {search}
					/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return { 
		catId: state.ids.catId, 
		rssId: state.ids.rssId,
		siteTitle: state.siteTitle.title,
		cats: Object.values(state.menu.cats), 
		rss: Object.values(state.menu.rss)
	};
};

export default connect(mapStateToProps, { setCatId, setRssId })(Main);