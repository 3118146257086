import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IconSearch } from '../../assets/icons';
import { setSearchQuery } from '../../actions';

class SearchForm extends React.Component {
	
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	handleChange(event) {
		this.props.setSearchQuery(event.target.value);
	}
	
	handleSubmit(event) {
		event.preventDefault();
		let submitValue = this.props.value;
		this.props.setSearchQuery('');
		this.props.history.push(`/news/search/${submitValue}`)
	}
	
	setSearchFormClass() {
		if(this.props.value !== '') {
			return 'SearchForm--in-focus';
		} else {
			return '';
		}
	}
	
	render () {
		let placeholderText = "Шукати";
		return (
			<div className={`SearchForm ${this.setSearchFormClass()}`}>
				<form className="SearchForm__form" onSubmit={this.handleSubmit}>
					<input 
						className="SearchForm__input" 
						type="text" 
						value={this.props.value} 
						placeholder={placeholderText}  
						onChange={this.handleChange} 
						onFocus={(e) => e.target.placeholder = ""} 
  						onBlur={(e) => e.target.placeholder = placeholderText}
						/>
					<button className="SearchForm__button">
						<IconSearch />
					</button>
				</form>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return { 
		value: state.searchQuery.value
	}
};

const searchFormExport = withRouter(SearchForm);

export default connect(mapStateToProps, { setSearchQuery })(searchFormExport);