import React from 'react';
import { Link } from 'react-router-dom';
import TrackVisibility from 'react-on-screen';
import { connect } from 'react-redux';
import { setNewsRead } from '../../actions';

class NewsItem extends React.Component {
	
	renderImg() {
		let imageHtml = "";
		if (this.props.data.img) {
			imageHtml = <img className="NewsItem__img" src={ `https://novyny.pp.ua${this.props.data.img}` } alt={this.props.data.title} />;
		}
		return imageHtml;
	}
	
	renderItem(isVisible) {
		let itemClassName = this.props.itemClassName;
		
		if(isVisible) {
			if (this.props.userId && this.props.itemClassName === "") {
				this.props.setNewsRead(this.props.userId, this.props.data.id);
				itemClassName = 'NewsItem--read';
			}
		}
		return (
			<div className={`NewsItem mb-m ${itemClassName}`}>
				<h3 className="heading-3 NewsItem__heading mb-s">
					<Link className="NewsItem__link" to={ `/news/${this.props.data.id}` }>{this.props.data.title}</Link>
				</h3>
				<div className="NewsItem__content">
					{this.renderImg()}
					<div className="NewsItem__description">
						<div className="NewsItem__atrbs mb-xs">
							<Link to={ `/sources/${this.props.data.rss_id}` } className="NewsItem__source">{this.props.data.rss_title}</Link>
							<Link to={ `/categories/${this.props.data.cat_id}` } className="NewsItem__category">{this.props.data.cat}</Link>
							<span className="NewsItem__time">{this.props.data.pub_date}</span>
						</div>
						<p className="NewsItem__lead">{this.props.data.lead}</p>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<TrackVisibility once>
				{({ isVisible }) => this.renderItem(isVisible) }
			</TrackVisibility>
		);
	}
	
}

const mapStateToProps = state => {
	return { setRead: state.setRead.status };
};

export default connect(mapStateToProps, { setNewsRead })(NewsItem);